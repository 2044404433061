import React, { useState } from "react";
import { useFormik } from "formik";
import { message } from "antd";

import { LoginParamsType, loginApi, getPk } from "src/services/auth";
import { LoginMethods } from "src/pages/Login";
import jwt from "jsonwebtoken";

interface IProps {
	changeLoginMethod: (method: LoginMethods) => void;
}

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: LoginParamsType) => {
	const errors: any = {};
	if (!values.email) {
		errors.email = "Email required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (!values.password) {
		errors.password = "Password required";
	}

	return errors;
};

const Login = ({ changeLoginMethod }: IProps) => {
	const formik = useFormik({
		initialValues: {
			password: "",
			email: "",
		},
		validate,
		onSubmit: (values) => {
			login(values);
		},
	});

	const login = async (values: LoginParamsType) => {
		const resp = await loginApi(values);
		if (resp?.status && resp?.data) {
			const {
				data: { token },
			} = resp;

			const entry = process.env.REACT_APP_ENTRY;
			const { data } = await getPk();
			try {
				const payload: any = jwt.verify(token, data, {
					algorithms: ["RS256"],
				});
				if (entry === "admin") {
					if (!payload.role || payload.role !== "super.admin") {
						message.warn("You don't have admin permission 🧑");
						return;
					}
				}
				localStorage.setItem("token", token);
				localStorage.setItem("pk", data);
				window.location.href = "/";
			} catch (err: any) {
				if (err.name === "TokenExpiredError") {
					message.error("Authentication token expired 😫");
					return;
				}
				message.error("Error validating token." + err.message);
			}
		}
	};

	const isWebAuthnAvailable = () => {
		return (
			location.hostname.includes("mdm.dotworld.tech") ||
			location.hostname.includes("mdm.dotworld.dev") ||
			location.hostname.includes("localhost")
		);
	};

	return (
		<div className="flex flex-col bg-white justify-center flex-1 px-4 py-12 overflow-hidden  sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div className="w-full max-w-xl mx-auto lg:w-96">
				<div>
					<p className="text-gray-800 text-medium">
						Enter your email associated with Command Center
					</p>
					<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
						Sign in.
					</h2>
				</div>
				<div className="mt-8">
					<div className="mt-6">
						<form className="space-y-6" onSubmit={formik.handleSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-black"
								>
									Email address
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="text"
										autoComplete="email"
										required
										placeholder="Your Email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
										className="block w-full px-5 py-3 text-base text-black placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg  bg-gray-100 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
									/>
								</div>
								{formik.touched.email && formik.errors.email && (
									<span className="inline-block mt-1 font-small text-pink-500">
										{formik.errors.email}
									</span>
								)}
							</div>
							<div className="space-y-1">
								<label
									htmlFor="password"
									className="block text-sm font-medium text-black"
								>
									Password
								</label>
								<div className="mt-1">
									<input
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										placeholder="Your Password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.password}
										className="block w-full px-5 py-3 text-base text-black placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg  bg-gray-100 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
									/>
								</div>
								{formik.touched.password && formik.errors.password && (
									<span className="inline-block mt-1 font-small text-pink-500">
										{formik.errors.password}
									</span>
								)}
							</div>
							<div className="flex items-center justify-between">
								<div className="text-sm">
									<a
										href=" "
										className="font-medium text-blue-400 hover:text-blue-500"
										onClick={(e) => {
											e.preventDefault();
											changeLoginMethod(LoginMethods.FORGOT_PASS);
										}}
									>
										Forgot your password?
									</a>
								</div>
							</div>
							<div>
								<button
									type="submit"
									className="flex bg-blue-600 items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform  rounded-xl hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									Sign in
								</button>
							</div>
						</form>

						{isWebAuthnAvailable() && (
							<>
								<div className="relative my-4">
									<div className="absolute inset-0 flex items-center">
										<div className="w-full border-t border-gray-300"></div>
									</div>
									<div className="relative flex justify-center text-sm">
										<span className="px-2 text-black bg-white">
											Or continue with
										</span>
									</div>
								</div>
								<div>
									<button
										type="button"
										onClick={(e) => {
											e.preventDefault();
											changeLoginMethod(LoginMethods.WEBAUTHN);
										}}
										className="
													w-full
													items-center
													block
													px-10
													py-3.5
													text-base
													font-medium
													text-center text-blue-400
													transition
													duration-500
													ease-in-out
													transform
													border-2 border-white
													shadow-md
													rounded-xl
													focus:outline-none
													focus:ring-2
													focus:ring-offset-2
													focus:ring-gray-500
													"
									>
										<div className="flex items-center justify-center">
											<span className="ml-4 text-blue-600"> WebAuthn</span>
										</div>
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
