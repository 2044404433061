import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { sendMagicLink } from "src/services/auth";
import { LoginMethods } from "src/pages/Login";

interface IProps {
	changeLoginMethod: (method: LoginMethods) => void;
}

const LoginWithLink = ({ changeLoginMethod }: IProps) => {
	interface IValue {
		email: string;
	}

	const validate = (value: { email: string }): Record<string, unknown> => {
		const errors: any = {};
		if (!value.email) {
			errors.email = "Email  is required.";
		}
		return errors;
	};

	const handleSubmit = async (
		values: IValue,
		actions: FormikHelpers<IValue>
	) => {
		const res = await sendMagicLink(values.email);
	};

	return (
		<div className="flex flex-row items-center h-full ">
			<div className="grid grid-cols-1 gap-10 ml-16 md:ml-8 sm:ml-6">
				<p className="text-purple-600 text-opacity-100 text-3xl font-medium">
					Login With Magic Link
				</p>
				<p className="text-left text-gray-500">
					Enter your email associated with your CommandCenter account.
				</p>
				<Formik
					initialValues={{
						email: "",
					}}
					validate={validate}
					onSubmit={handleSubmit}
				>
					{({ errors, setFieldValue, values }) => (
						<Form>
							<label htmlFor="email" className="block mb-6">
								<span className="text-1xl text-black font-medium">Email</span>
								<input
									type="email"
									autoComplete="on"
									id="email"
									placeholder="commandcenter@example.com"
									name="email"
									value={values.email}
									onChange={(e) => setFieldValue("email", e.target.value)}
									className="input w-full"
								/>
								{errors.email && (
									<span className="inline-block mt-1 font-small text-pink-500">
										{errors.email}
									</span>
								)}
							</label>
							<button type="submit" className="btn btn-primary">
								Passwordless Login
							</button>
						</Form>
					)}
				</Formik>

				<div className="flex flex-col space-y-4">
					<a
						className="text-purple-500 font-normal hover:underline"
						href=" "
						onClick={(e) => {
							e.preventDefault();
							changeLoginMethod(LoginMethods.LOGIN);
						}}
					>
						Login
					</a>
					<a
						className="text-purple-500 font-normal hover:underline"
						href=" "
						onClick={(e) => {
							e.preventDefault();
							changeLoginMethod(LoginMethods.FORGOT_PASS);
						}}
					>
						Forgot Password?
					</a>
				</div>
			</div>
		</div>
	);
};

export default LoginWithLink;
