import React from "react";
import { useFormik, FormikHelpers } from "formik";
import {
	getWebAuthnChallenge,
	verifyChallengeResp,
	getPk,
} from "src/services/auth";
import { LoginMethods } from "src/pages/Login";
import { startAssertion } from "@simplewebauthn/browser";

interface IProps {
	changeLoginMethod: (method: LoginMethods) => void;
}

interface IValue {
	email: string;
}

const WebAuthnLogin = ({ changeLoginMethod }: IProps) => {
	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validate,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	function validate(value: { email: string }): Record<string, unknown> {
		const errors: any = {};
		if (!value.email) {
			errors.email = "Email  is required.";
		}
		return errors;
	}

	const handleSubmit = async (values: IValue) => {
		try {
			const challenge = await getWebAuthnChallenge(values.email);
			if (challenge.status) {
				const assertionResp = await startAssertion(challenge.data);
				const authResp = await verifyChallengeResp({
					email: values.email,
					data: assertionResp,
				});
				if (authResp.status) {
					const { token } = authResp.data;
					localStorage.setItem("token", token);
					const { data } = await getPk();
					localStorage.setItem("pk", data);

					window.location.href = "/";
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="flex flex-col bg-white justify-center flex-1 px-4 py-12 overflow-hidden  sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div className="w-full max-w-xl mx-auto lg:w-96">
				<div>
					<p className="text-gray-800 text-medium">
						Enter your email associated with Command Center
					</p>
					<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
						Try WebAuthn.
					</h2>
				</div>
				<div className="mt-8">
					<div className="mt-6">
						<form className="space-y-6" onSubmit={formik.handleSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-black"
								>
									Email address
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="text"
										autoComplete="email"
										required
										placeholder="Your Email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
										className="block w-full px-5 py-3 text-base text-black placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg  bg-gray-100 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
									/>
								</div>
								{formik.touched.email && formik.errors.email && (
									<span className="inline-block mt-1 font-small text-pink-500">
										{formik.errors.email}
									</span>
								)}
							</div>
							<div>
								<button
									type="submit"
									className="flex bg-blue-600 items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform  rounded-xl hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									Passwordless login
								</button>
							</div>
						</form>

						<div className="relative my-4">
							<div className="absolute inset-0 flex items-center">
								<div className="w-full border-t border-gray-300"></div>
							</div>
							<div className="relative flex justify-center text-sm">
								<span className="px-2 text-black bg-white">Or continue</span>
							</div>
						</div>
						<div>
							<button
								type="button"
								onClick={(e) => {
									e.preventDefault();
									changeLoginMethod(LoginMethods.LOGIN);
								}}
								className="
													w-full
													items-center
													block
													px-10
													py-3.5
													text-base
													font-medium
													text-center text-blue-400
													transition
													duration-500
													ease-in-out
													transform
													border-2 border-white
													shadow-md
													rounded-xl
													focus:outline-none
													focus:ring-2
													focus:ring-offset-2
													focus:ring-gray-500
													"
							>
								<div className="flex items-center justify-center">
									<span className="ml-4 text-blue-600">
										{" "}
										Login with password
									</span>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WebAuthnLogin;
