import React, { ReactNode, useState } from "react";
import ForgotPassword from "../components/login/ForgotPassword";

import Login from "src/components/login/Login";
import WebAuthnLogin from "src/components/login/WebAuthnLogin";
import SignWithLink from "src/components/login/LoginWithLink";
import LoginImage from "../assets/login.webp";

export type LoginProps = {
	setToken(token: string): void;
	children?: ReactNode;
};

export enum LoginMethods {
	LOGIN,
	FORGOT_PASS,
	WEBAUTHN,
	LOGIN_WITH_LINK,
}

const PROD_IMAGE =
	"https://images.unsplash.com/photo-1585906102129-429f64c19ab9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1481&q=80";
const STAGE_IMAGE =
	"https://images.unsplash.com/photo-1600664356348-10686526af4f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1475&q=80";

const LoginPage: React.FC<LoginProps> = () => {
	const [loginMethod, setLoginMethod] = useState<LoginMethods>(
		LoginMethods.LOGIN
	);

	const changeLoginMethod = (method: LoginMethods) => {
		setLoginMethod(method);
	};

	const displayComponents = () => {
		switch (loginMethod) {
			case LoginMethods.LOGIN:
				return <Login changeLoginMethod={changeLoginMethod} />;
			case LoginMethods.FORGOT_PASS:
				return <ForgotPassword changeLoginMethod={changeLoginMethod} />;
			case LoginMethods.WEBAUTHN:
				return <WebAuthnLogin changeLoginMethod={changeLoginMethod} />;
			case LoginMethods.LOGIN_WITH_LINK:
				return <SignWithLink changeLoginMethod={changeLoginMethod} />;
		}
	};

	return (
		<section className="overflow-hidden">
			<div className="flex min-h-screen overflow-hidden">
				<div className="relative flex-1 hidden w-0 overflow-hidden lg:block">
					<img
						className="absolute inset-0 object-cover w-full h-full bg-black"
						src={
							location.hostname.includes("dotworld.tech")
								? PROD_IMAGE
								: STAGE_IMAGE
						}
						alt="Login Page image"
					/>
				</div>
				{displayComponents()}
			</div>
		</section>
	);
};

export default LoginPage;
