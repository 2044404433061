import jwt from "jsonwebtoken";

export interface IPayload {
	id: string;
	role?: string;
	email: string;
	name?: string;
	exp?: number;
	iat?: number;
}

export function isAuthenticated(): boolean {
	const pk = window.localStorage.getItem("pk");
	const token = window.localStorage.getItem("token");
	if (!pk || !token) {
		return false;
	}

	try {
		jwt.verify(token, pk, {
			algorithms: ["RS256"],
		});
		return true;
	} catch (error) {
		return false;
	}
}

export function decodePayload(): IPayload | null {
	const token = localStorage.getItem("token");
	if (token === null) {
		return null;
	}
	return jwt.decode(token) as IPayload;
}
