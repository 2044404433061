import React, { ReactNode, useEffect, useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";

import { decodePayload } from "../../utils/auth";

import { MenuList } from "src/interfaces/common";
import { AuthContext } from "src/contexts/AuthContext";

const { Header, Content, Sider } = Layout;

interface Props {
	needSider: boolean | true;
	children: ReactNode;
	menuList: MenuList[];
}

function SiderDemo(props: Props) {
	const payload = decodePayload();

	const location = useLocation();
	const [selectedKey, setSelectedKey] = useState(
		props.menuList.find((_item) => location.pathname.startsWith(_item.route))
			?.key
	);

	useEffect(() => {
		setSelectedKey(
			props.menuList.find((_item) => location.pathname.startsWith(_item.route))
				?.key
		);
	}, [location]);

	const loggout = () => {
		localStorage.clear();
		window.location.href = "/";
	};

	return (
		<AuthContext.Consumer>
			{({ is_authenticated }) => (
				<Layout>
					{is_authenticated && (
						<Sider width="200" trigger={null} breakpoint="sm" theme="light">
							<img
								className="h-12 mt-4 w-full object-scale-down"
								src={
									"https://interlocksecurity.com.sg/wp-content/uploads/2021/01/Interlock-Icon-150-1.png"
								}
								alt={"CommandCenter"}
							/>

							<Menu
								theme="light"
								className={"mt-6"}
								mode="inline"
								defaultSelectedKeys={[selectedKey ? selectedKey : "dashboard"]}
							>
								{props.menuList.map((value) => (
									<>
										<Menu.Item
											className={"flex items-center place-content-left "}
											key={value.key}
											icon={value.icon}
										>
											<Link to={value.route}>
												{_.startCase(_.camelCase(value.key))}
											</Link>
										</Menu.Item>
									</>
								))}
							</Menu>
						</Sider>
					)}
					<Layout className={"h-screen overflow-y-auto gradient-bg"}>
						{is_authenticated && (
							<Header className={"px-8 flex my-2 gradient-bg"}>
								<div className="flex-1"></div>
								<div className="float-right flex items-center">
									<Link to="/profile">
										<div className="px-2 flex items-center">
											<Avatar className="bg-blue-700" size={40}>
												{payload &&
													payload.email.substr(0, 1).toLocaleUpperCase()}
											</Avatar>
											<p className="font-medium text-blue-700 dark:mt-2 inline px-1 sm:px-2 sm:self-center">
												{payload && payload.name}
											</p>
										</div>
									</Link>
									<button className="flex items-center text-xl text-red-500 	">
										<PoweroffOutlined className="power-btn" onClick={loggout} />
									</button>
								</div>
							</Header>
						)}
						<Content className={`${is_authenticated ? "pt-2 px-6" : "p-0"}`}>
							{props.children}
						</Content>
					</Layout>
				</Layout>
			)}
		</AuthContext.Consumer>
	);
}

export default SiderDemo;
