import React, { useState, ReactChild } from "react";
import { INode } from "src/interfaces/admin/sip";

interface INodeCount {
	setNodes: (n: INode[]) => void;
	nodes: INode[];
	node: INode | null;
}

export const NodeCountContext = React.createContext<INodeCount | null>(null);

export default ({ children }: { children: ReactChild }) => {
	const [nodes, setCustomNodes] = useState<INode[]>([]);
	const [node, setRemovedNode] = useState<INode | null>(null);
	const setNodes = (n: INode[]) => {
		setCustomNodes(n);
	};

	const handleRemovedNode = (n: INode) => {
		setRemovedNode(n);
	};

	return (
		<NodeCountContext.Provider value={{ setNodes, nodes, node }}>
			{children}
		</NodeCountContext.Provider>
	);
};
