import React, { useState } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	RouteProps,
	Switch,
} from "react-router-dom";
import {
	DesktopOutlined,
	UserOutlined,
	AndroidOutlined,
	CompassOutlined,
	AuditOutlined,
	UsergroupAddOutlined,
	AudioOutlined,
	UnorderedListOutlined,
	DashboardOutlined,
	VideoCameraOutlined,
} from "@ant-design/icons";
import { io } from "socket.io-client";

import { MenuList } from "../interfaces/common";
import { isAuthenticated } from "src/utils/auth";
import Sider from "src/components/admin/Sider";
import LoginPage from "src/pages/Login";

import NodeCountContext from "src/contexts/CustomNodeContext";

const SOCKET_URL: string | undefined = process.env.REACT_APP_WS_HOST;

type PrivateRouteProps = RouteProps;
export const PrivateRoute = ({
	component: Component,
	...rest
}: PrivateRouteProps) => {
	if (!Component) return null;
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated() ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: "/", state: { from: props.location } }} />
				)
			}
		/>
	);
};

const App = () => {
	const [_token, setToken] = useState(localStorage.getItem("token"));
	const MENU_LIST: MenuList[] = [
		{
			key: "dashboard",
			route: "/dashboard",
			component: React.lazy(() => import("../pages/Dashboard")),
			icon: <DashboardOutlined />,
			exact: true,
		},
		{
			key: "locations",
			route: "/locations",
			component: React.lazy(() => import("../pages/Locations")),
			icon: <CompassOutlined />,
			exact: true,
		},
		{
			key: "users",
			route: "/users",
			component: React.lazy(() => import("../pages/Users")),
			icon: <UserOutlined />,
			exact: true,
		},
		{
			key: "roles",
			route: "/roles",
			component: React.lazy(() => import("../pages/Roles")),
			icon: <UsergroupAddOutlined />,
			exact: true,
		},
		{
			key: "permissions",
			route: "/permissions",
			component: React.lazy(() => import("../pages/Permissions")),
			icon: <AuditOutlined />,
			exact: true,
		},
		{
			key: "devices",
			route: "/devices",
			component: React.lazy(() => import("../pages/Devices")),
			icon: <DesktopOutlined />,
			exact: true,
		},
		{
			key: "cameras",
			route: "/cameras",
			component: React.lazy(() => import("../pages/Cameras")),
			icon: <VideoCameraOutlined />,
			exact: true,
		},
		{
			key: "Softwares",
			route: "/softwares",
			component: React.lazy(() => import("../pages/Softwares")),
			icon: <AndroidOutlined />,
			exact: false,
		},
		{
			key: "Activity Logs",
			route: "/activity",
			component: React.lazy(() => import("../pages/ActivityPage")),
			icon: <UnorderedListOutlined />,
			exact: false,
		},
		{
			key: "sip",
			route: "/sip",
			component: React.lazy(() => import("../pages/Sip")),
			icon: <AudioOutlined />,
			exact: true,
		},
		{
			key: "adb",
			route: "/adb",
			component: React.lazy(() => import("../pages/WebAdb")),
			icon: <AndroidOutlined />,
			exact: true,
		},
	];

	if (SOCKET_URL) {
		const socket = io(SOCKET_URL, {
			path: "/command-center",
			reconnectionDelayMax: 20000,
			extraHeaders: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});

		socket.on("connect", () => {
			console.log("Socket connected");
		});

		socket.io.on("error", (error) => {
			console.error(error);
		});

		socket.onAny((eventName, ...args) => {
			let data = undefined;
			if (args.length > 0) {
				data = args[0].data;
			}
			const event = new CustomEvent(eventName, { detail: data });
			document.dispatchEvent(event);
		});
	}

	return (
		<>
			<div className="App w-screen h-screen">
				<NodeCountContext>
					<Router>
						<Switch>
							<Sider menuList={MENU_LIST} needSider={true}>
								<React.Suspense fallback="Loading...">
									<Route
										exact
										path="/"
										render={() =>
											isAuthenticated() ? (
												<Redirect to="/dashboard" />
											) : (
												<LoginPage setToken={setToken} />
											)
										}
									/>
									<>
										{MENU_LIST.map((value, index) => (
											<PrivateRoute
												exact={value.exact}
												key={index}
												path={value.route}
												component={value.component}
											/>
										))}
									</>

									<PrivateRoute
										exact
										path={"/search"}
										component={React.lazy(() => import("src/pages/Devices"))}
									/>

									<PrivateRoute
										exact
										path={"/devices/:deviceId"}
										component={React.lazy(
											() => import("../components/admin/devices/DeviceDetail")
										)}
									/>
									<PrivateRoute
										exact
										path={"/profile"}
										component={React.lazy(() => import("../pages/Profile"))}
									/>
									<PrivateRoute
										exact
										path={"/users/:userId"}
										component={React.lazy(
											() => import("../components/admin/users/UserDetail")
										)}
									/>
									<PrivateRoute
										exact
										path={"/sip/:deviceId/flow"}
										component={React.lazy(
											() => import("../components/admin/sip/flow/SipFlow")
										)}
									/>

									<PrivateRoute
										exact
										path="/cameras/:deviceId"
										component={React.lazy(() => import("src/pages/CameraView"))}
									/>

									<PrivateRoute
										exact
										path={"/setup"}
										component={React.lazy(
											() => import("../components/webadb/Setup")
										)}
									/>

									<PrivateRoute
										exact
										path={"/current_app"}
										component={React.lazy(() => import("src/pages/CurrentApp"))}
									/>

									<PrivateRoute
										exact
										path={"/current_version"}
										component={React.lazy(() => import("src/pages/MdmVersion"))}
									/>
								</React.Suspense>
							</Sider>
							<Route
								exact
								path={"/404"}
								component={React.lazy(() => import("../pages/404"))}
							/>
						</Switch>
					</Router>
				</NodeCountContext>
			</div>
		</>
	);
};
export default App;
