import { Login, Resp } from "src/interfaces/common";
import request from "../utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

export interface LoginParamsType {
	email: string;
	password: string;
}

export const loginApi = async (data: LoginParamsType): Promise<Resp<Login>> => {
	return request(`${HOST}/api/v1/auth`, {
		method: "POST",
		data,
	});
};

export const getPk = async (): Promise<Resp<any>> => {
	return request(`${HOST}/api/v1/auth/pk`, {
		method: "GET",
	});
};
export const sendMagicLink = async (email: string): Promise<Resp<string>> => {
	return request(`${HOST}/api/v1/auth/magic?email=${email}`, {
		method: "GET",
	});
};

export const exchangeMagicToken = async (token: string): Promise<any> => {
	return request(`${HOST}/api/v1/auth/magic/exchange`, {
		method: "POST",
		data: {
			token,
		},
	});
};

export const generateAssertion = async (email: string): Promise<Resp<any>> => {
	return request(`${HOST}/api/v1/auth/webauthn/challenge?email=${email}`, {
		method: "GET",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
		},
	});
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const verifyAttestation = async (data: any): Promise<Resp<any>> => {
	return request(`${HOST}/api/v1/auth/webauthn`, {
		method: "POST",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("token"),
		},
		data: JSON.stringify(data),
	});
};

export const getWebAuthnChallenge = async (
	email: string
): Promise<Resp<any>> => {
	return request(
		`${process.env.REACT_APP_API_HOST}/api/v1/auth/webauthn/challenge`,
		{
			method: "GET",
			params: {
				email,
			},
		}
	);
};

export const verifyChallengeResp = async ({
	email,
	data,
}: {
	email: string;
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	data: any;
}): Promise<Resp<any>> => {
	return request(`${process.env.REACT_APP_API_HOST}/api/v1/auth/webauthn`, {
		method: "POST",
		data: {
			email,
			data,
		},
	});
};

export const resetPassApi = async (data: {
	email: string;
}): Promise<Resp<any>> => {
	return request(`${process.env.REACT_APP_API_HOST}/api/v1/auth/reset`, {
		method: "POST",
		data,
	});
};
