import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

import "../styles/index.css";

console.log(process.env.REACT_APP_ENVIRONMENT);
console.log(process.env.GENERATE_SOURCEMAP);

Sentry.init({
	dsn: "https://07331f0d3f6144b8ac24913e162dddcc@o484490.ingest.sentry.io/6200158",
	integrations: [new BrowserTracing()],
	environment: process.env.REACT_APP_ENVIRONMENT,
	debug: true,
	initialScope: {
		tags: { role: "admin" },
	},
	tracesSampleRate: 1.0,
});

if (window.location.host.includes("cc-admin-stage.web.app")) {
	window.location.replace(
		`https://mdm.dotworld.dev${window.location.pathname}`
	);
} else if (window.location.host.includes("cc-admin-prod.web.app")) {
	window.location.replace(
		`https://mdm.dotworld.tech${window.location.pathname}`
	);
}

const container = document.getElementById("root");
if (container) {
	createRoot(container).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
}
